import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { TextDataService } from 'src/app/text-data.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-highlighted-result',
	templateUrl: './highlighted-result.component.html',
	styleUrls: ['./highlighted-result.component.css']
})
export class HighlightedResultComponent implements OnInit, AfterViewInit {

	textResult: string;
	demo = `Há inúmeras maneiras de fazer um loop em arrays e objetos em JavaScript, 
			e as diferenças são uma causa comum de confusões. Alguns guias de estilo 
			vão tão longe a ponto de proibir certas construções em loop. Neste artigo, 
			iremos ver as diferenças entre iterar em uma array com as 4 construções 
			<mark class="highlight">primárias</mark> de loop:`
	
	@ViewChild('text', {static: false}) textOutput: ElementRef<HTMLDivElement>;

	constructor(
		private textDataService: TextDataService,
		private router: Router,
	) { }

	ngOnInit() {
		this.textResult = this.textDataService.getResult();
		if(!this.textResult) {
			this.router.navigate(['']);
		}
	}

	ngAfterViewInit() {
		this.textOutput.nativeElement.innerHTML = this.textResult;
	}

}
