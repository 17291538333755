import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TextDataService } from 'src/app/text-data.service';
import { DataInput } from 'src/app/models/data-input';

@Component({
	selector: 'app-start',
	templateUrl: './start.component.html',
	styleUrls: ['./start.component.css']
})
export class StartComponent implements OnInit {

	dataInput: DataInput;
	@ViewChild('text', {static: false}) textInput: ElementRef<HTMLDivElement>;
	@ViewChild('words', {static: false}) wordsInput;

	constructor(
		private textDataService: TextDataService,
	) { }

	ngOnInit() {
	}

	search() {
		const text = this.textInput.nativeElement.innerText;
		const words = this.wordsInput.nativeElement.innerText;
		this.dataInput = new DataInput(words, text);

		this.textDataService.setDataInput(this.dataInput);
		this.textDataService.makeResult();
	}
}
