import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StartComponent } from './components/start/start.component';
import { HighlightedResultComponent } from './components/highlighted-result/highlighted-result.component';

const routes: Routes = [
	{ path: '', component: StartComponent },
	{ path: 'resultado', component: HighlightedResultComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
