import { Injectable } from '@angular/core';
import { DataInput } from './models/data-input';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class TextDataService {

	dataInput: DataInput;
	hash: Object = new Object();
	splitText = [];
	splitWords = [];
	result: string;

	constructor(
		private router: Router
	) { }

	setDataInput(dataInput) {
		this.dataInput = dataInput;
	}

	getDataInput() {
		return this.dataInput;
	}

	clearToken(token) {
		const clearedToken = token.replace(/\W|_/g, '');
		return clearedToken.toUpperCase();
	}

	makeHashTable() {
		this.splitWords = this.dataInput.wordsList.trim().split(/[\s]/);

		this.splitWords.forEach(word => {
			this.hash[word.toUpperCase()] = [];
		});
	}

	populateHash() {
		this.splitText = this.dataInput.text.split(/(\s+)/);
		const newText = this.splitText.slice();

		this.splitText.forEach((word: string, index) => {
			const clearedToken = this.clearToken(word);
			const token = this.hash[clearedToken];
			
			if(token) {
				const newToken = this.highlightWord(word);
				newText[index] = newToken;
				this.hash[clearedToken].push(index);
			}
		});

		this.result = newText.join('');
	}

	highlightWord(word: string) {
		const rawToken = word.replace(/\W|_/g, '');
		const symbols = word.replace(rawToken, '');
		const marked = '<mark>' + rawToken + '</mark>' + symbols;

		return marked;
	}

	makeResult() {
		this.makeHashTable();
		this.populateHash();
		this.router.navigate(['/resultado']);
	}

	getResult() {
		return this.result;
	}
}
