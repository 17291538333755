import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { StartComponent } from './components/start/start.component';
import { HighlightedResultComponent } from './components/highlighted-result/highlighted-result.component';

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    HighlightedResultComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
